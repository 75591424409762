<template>
  <div class="collect_search">
    <search></search>
    <mp-waterfall :data="collectData" @loadmore="loadmore"></mp-waterfall>
    <no-more :show="noMore && collectReq.page != 1"></no-more>
  </div>
</template>

<script>
import Search from '@/mp/components/mp-collect-search';
import MpWaterfall from '@/mp/components/mp-waterfall';
import NoMore from '@/mp/components/mp-no-more';
export default {
  components: {
    Search,
    MpWaterfall,
    NoMore,
  },
  data() {
    return {
      collectData: [],
      collectReq: {catId: '', username: '', ord: 0, title: '', use: 0, page: 1},
      noMore: false,
    }
  },
  methods: {
    getCollectData() {  // 采集数据
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/list',
        method: 'GET',
        params: {
          cat_id: this.collectReq.catId,
          username: this.collectReq.username,
          ord: this.collectReq.ord,
          title: this.collectReq.title,
          use: this.collectReq.use,
          page: this.collectReq.page,
        },
      }).then(res => {
        if (res.code == 200) {
          if (res.data.res.length < 20) {
            this.noMore = true;
          } else {
            this.collectData = this.collectData.concat(res.data.res)
          }
        }
      })
    },
    loadmore() {  // 加载更多采集
      this.collectReq.page += 1;
      this.getCollectData();
    },
  },
  created() {
    this.collectReq.title = this.$route.query.by;
    this.getCollectData();
  },
}
</script>

<style lang="scess" scoped>

</style>