<template>
  <div class="no_more" v-if="show">别扯了，没有更多了</div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  }
}
</script>

<style lang="scss" scoped>
.no_more {
  width: 100;
  padding: 10px 0;
  text-align: center;
  color: #999;
}
</style>