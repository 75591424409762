<template>
  <div class="search_box">
    <div class="search_input">
      <input type="search" placeholder="搜索您喜欢的内容" class="input" v-model="input" @keyup.enter="search">
      <i class="el-icon-search flag" @click="search"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: '',
    }
  },
  methods: {
    search() {
      if (!this.input) return;
      this.$router.push({
        path: '/mCollectSearch',
        query: { by: this.input }
      });
    },
  },
  created() {
    this.input = this.$route.query.by;
  },
}
</script>

<style lang="scss" scoped>
.search_box {
  width: 100%;
  background: #fff;
  padding: 3px 0;
  .search_input {
    box-sizing: border-box;
    width: 90%;
    height: 2rem;
    border: 1px solid #ededed;
    border-radius: 50px;
    font-size: 1rem;
    padding: 0 40px 0 20px;
    margin: 0 auto;
    background: #fafafa;
    position: relative;
    .input {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      outline: 0;
      border: none;
      background: inherit;
    }
    .flag {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 20px;
      color: #aaa;
    }
  }
}
</style>